import { createApp } from 'vue'
import App from './App.vue'  
import ElementPlus  from './plugins/element' 
import router from './router/index' 
import store from './store/index'
import './permission'
const app = createApp(App) 

ElementPlus(app)
// 使用vue-router
app.use(router)
  .use(store)
// 挂载
app.mount('#app')
