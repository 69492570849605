import * as CryptoJS  from 'crypto-js'
import  JSEncrypt  from 'jsencrypt'
export default function des(){
  // 公
  const miwen = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCVUToM9FQFLLapo74RnW1R9aZcfEqepQ05p0NZ95EE6UUX40F4oVJCjZDnUen7B+Q39BJHxvlvU6k+fvXV1zqXsR/XxLruoT/K17XxV1regv3OChFhcuFokmv95AXmDGyoPFNQhrgUFJvx5WNhuhKGH6tD+FulvF/N0+QLG/mz2wIDAQAB-----END PUBLIC KEY-----'
  // 私
  const pri = `-----BEGIN RSA PRIVATE KEY-----MIICXAIBAAKBgQCTKszwoXxl2FKaCKbmIVhSkNarzuqjhIWgf8e7aDXazkkTv2l7oIxKBPTn1LDPC2+y6yLjNrOMD03TP7tT8HKLreV6Jf8s6b59eBoNrQiFlgyaS3Zg5MpB9uP99MCBUzu98rQLrbBcsfmxGXG/jHlHXZ8aUUn66dsGP0qCcEy8UQIDAQABAoGAA3mZwD7X7KG4hZmZs0prRvvTjdgDc1wAOcN6qGK0DtgbIqO87mXimibWz5iW
  mjT3bL6fosTaZX7BBDF3j2pYkgmXu3c/Ib1X3aG0HvyC6GA72Zbz6WOf3uVxWwCynZ8PxJ1++CfrRBeAHSVm/mJD59KTTYQrCh/iVlOVHnyhCAECQQC1gaMfs3RNsif0Pm6BXsfxwuS33lViApBhHtyTEMcTktr/3GLLftJoCKniK/3UqSJvsS3xUGIW5to/5MHruosBAkEAz5E9gsOopl64rYEEuLVBtnKZZ1d4r/tNx7AMHYKtpZTsVZbJaJ3k
  0Q/wYlfoDfQHEqI5jUsFmwuguHAUbnvBUQJBAIjmgboMacGciYQwfrr3WUnhXQ/OE4p8Mpf+ZhJKykzaLHJlsOKaAmtTdYdQ9QAsnfuuoN9taL6rWpxsl1OMfQECQAcRsz6Y202PPErscfS4d5x3GrJiUEHuFSAP5tm6cZ+KCuJ/WCyv8/MKz+0JgWW4QeVokP026OH262pcptV8cYECQDhiQiigcZ+eiR3HiFjrPzv21Ggk8FXspcbTM4eGzlCNxJ359Df0ht5IP0ubvn98dorMAgGdfM8PEhvNMoUTze0=-----END RSA PRIVATE KEY-----`

  /**
   * 加密
   * @param {*} message 
   * @returns 
   */
  function encryptDes(message = localStorage.getItem('token')+'@&'){
    let Infolen = message.length % 16
    message = message + 'megatechmegatech'.substring(0,16-Infolen)
    let len = 8
    let pwd = ''
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = chars.length   
    for (let i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos))
    } 
    // noncestr 加密 
    let keyHex = CryptoJS.enc.Utf8.parse(pwd)
    let encrypted = CryptoJS.DES.encrypt(message, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    //  rsa 加密 
    let encrypt = new JSEncrypt() 
    encrypt.setPublicKey(miwen) // 设置私钥 
          
    let obj = {
      noncestr: encrypted.toString(),//encrypted.toString() 此方式返回base64 
      key : encrypt.encrypt(pwd),
      key2: pwd+ '_' + message
    }
    // console.log(obj.key)
    return  obj
  }
  /**
   * 解密
   * @param {*} msg 
   * @returns 
   */
  function decrypt(msg) { 
    let decrypt = new JSEncrypt()
    decrypt.setPrivateKey(pri)
    let decryptMsg = decrypt.decrypt(msg) 
    return JSON.parse(decryptMsg) 
  }
  return {
    encryptDes,decrypt
  }
}