import router from './router'
import {getLogin} from '@/axios/index'
import { requestIdleCallback2 } from './hooks/useFingerprintjs2'
import store from './store/index' 
import NProgress from 'nprogress' // progress bar
 
NProgress.configure({ showSpinner: false })
router.beforeEach(async(to, from, next) => {  
  NProgress.start()
  document.title = to?.meta.title || '智能训练'
  let token = localStorage.getItem('token') 
  // console.log('router.beforeEach',to,token) 
  let {dispatch,state} = store
  // console.log('store',state.user)
  if (!localStorage.getItem('browser')) { 
    await requestIdleCallback2()
  } 
  
  if (to.meta.notLogin) { 
    if(token){ 
      next('/home')
    }else{ 
      next()
    } 
    NProgress.done()
    return
  }
  if(state.user.loginStatus !=1 ){
    // console.log('token',state.user.loginStatus)
    getLogin('user_loginstate').then(async (res)=>{
      if(res.data.status === 1){ 
        let {userinfo,timestamp} = res.data
        dispatch('user/setUserInfo',userinfo)
        dispatch('user/setLoginStatus',1)
        localStorage.setItem('timestamp',JSON.stringify(new Date().getTime() - timestamp))
      }else{
        localStorage.removeItem('token')
        next('/login')
        NProgress.done()
        return
      }
    })
    next()
    NProgress.done()
  }else{ 
    next()
    NProgress.done()
  }   
})

router.afterEach(() => {
  
})