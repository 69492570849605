import { createRouter, createWebHistory } from "vue-router"

export const constanRoutes = [{
  path: '',
  redirect: '/home',
  component: () => import('@/layouts/index'),
  children: [{
    path: '/home',
    name: 'home',
    meta: {
      title: '数据驾驶舱',
      icon: 'home'
    },
    component: () => import('@/views/home/index'),
  }]
},
{
  meta: {
    title: '报表',
    icon: 'statements'
  },
  component: () => import('@/layouts/index'),
  children: [
    {
      path: '/tenantUsageDaily',
      name: 'TenantUsageDaily',
      meta: {
        title: '租户使用日报表',
      },
      component: () => import('@/views/statements/tenant-usage-daily.vue'),
    }, 
    {
      path: '/tenantTesourceMonitoring',
      name: 'TenantTesourceMonitoring.vue',
      meta: {
        title: '租户资源监控报表',
      },
      component: () => import('@/views/statements/tenant-resource-monitoring.vue'),
    },  
  ]
},
{
  path: '/tenantBody',
  name: 'TenantBody',
  meta: {
    title: '租户管理', 
    icon: 'tenant'
  },
  component: () => import('@/layouts/index'),
  children: [
    {
      path: '/tenant',
      name: 'Tenant',
      meta: {
        title: '租户管理',
        icon: 'tenant'
      },
      component: () => import('@/views/tenant/index.vue'),
    },  
  ]
},
{
  path: '/page',
  name: 'page',
  meta: {
    title: 'route',
    icon: 'Crop'
  },
  hidden:true,
  component: () => import('@/layouts/index'),
  children: [
    {
      path: '/addTenant',
      name: 'AddTenant',
      meta: {
        title: '租户管理', 
      },
      component: () => import('@/views/tenant/add-tenant.vue'),
      hidden: true
    }, 
    {
      path: '/detail',
      name: 'detail',
      meta: {
        title: '租户详情', 
      },
      component: () => import('@/views/tenant/detail.vue'),
      hidden: true
    }, 
    {
      path: '/page1',
      name: 'page1',
      meta: {
        title: 'page1',
      },
      component: () => import('@/views/route/page1.vue'),
    }, {
      path: '/page2',
      name: 'page2',
      meta: {
        title: 'page2',
      },
      component: () => import('@/views/route/page2.vue'),
    }
  ]
}, {
  path: '/login',
  component: () => import('@/views/login/index.vue'),
  meta: {
    title: '登录',
    notLogin:true
  },
  hidden: true
},{
  path: '/svg',
  component: () => import('@/views/plugins/svg.vue'),
  meta: {
    title: 'svg'
  },
  hidden: true
}
]

const router = createRouter({
  history: createWebHistory(),
  routes: constanRoutes
})
export default router
