const state = {
  loginStatus: -1,
  userinfo: {}, 
}
const mutations = {
  CHANGELOGINSTATUS(state,status){
    state.loginStatus = status
  },
  CHANGEUSERINFO(state,value){
    state.userinfo = value
  }
}
const actions = {
  setUserInfo({commit},info){
    commit('CHANGEUSERINFO',info)
  },
  setLoginStatus({commit},status){
    commit('CHANGELOGINSTATUS',status)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
