<template>
  <div class="pop-up-body">
    <div class="maskClass" @click.stop="closeByMask">全屏</div>
    <div class="pop-up-content" :style="contentStyle">
      <div class="head-box" :style="{'background':headBg}">
        <div class="title">{{title}}</div> 
        <div>
          <el-icon @click="closeByButton" v-if="showClose"><Close/></el-icon>
        </div>
      </div> 
      <div class="content-body">
        <slot>
          <div>
            <div class="" v-for="item in 5" :key="item">
              这是内容区{{item}}
            </div>
          </div>
        </slot>
      </div>
      <div class="bottom-box" v-if="showButton">
        <div class="button-back" @click="closeByButton">关闭</div>
        <div class="button-blue" type="primary" @click="confirmButon">{{confirmButtonText}}</div>
      </div>
    </div> 
  </div>
</template>
<script>
export default {
  props:{ 
    title:{
      type:String,
      default:''
    },
    /** 头部背景颜色 */
    headBg:{
      type:String,
      default:'#f2f6f9'
    },
    /** 是否展示按钮 */
    showButton:{
      type: Boolean,
      default: true
    },
    /** 确定按钮的文本内容 */
    confirmButtonText:{
      type:String,
      default:'确定'
    },
    /** 是否显示右上角关闭按钮 */
    showClose:{
      type:Boolean,
      default:true
    },
    /** 是否可通过点击遮罩关闭 */
    closeOnClickModal:{
      type:Boolean,
      default: false
    },
    contentStyle:{
      type:[Array,Object],
      default:()=>{}
    }
  },
  methods:{
    closeByMask(){
      this.closeOnClickModal && this.$emit('close','maskclose')
    },
    closeByButton(){
      this.$emit('close','closeByButton')
    },
    confirmButon(){
      this.$emit('sure','confirmButon')
    }
  }
}
</script>
<style lang="scss" scoped> 
.pop-up-body{
  position:fixed;
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%;
  z-index: 200;
  .maskClass{
    position: absolute;
    background: rgba(0,0,0,.5); 
    width: 100%;
    height: 100%;
  }
  .pop-up-content{
    position: absolute;
    background: #fff;
    width: 498px;
    height: 282px;
    border-radius: 6px;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .head-box{ 
      height: 57px;
      // line-height: 57px;
      border-radius: 6px 6px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .title{ 
        font-size:18px;
        color: #485057;
        font-weight: 600;
      }
      img{
        position: absolute;
        top: 20px;
        right: 20px;
        width:15.18px;
      }
    }
    .bottom-box{
      text-align: right;
      padding-right: 20px;
      // position: absolute; 
      // bottom: 60px;
      div:first-child{
        margin-right: 20px;
      }
    }
    .content-body{
      padding: 25px 20px;
      // height: calc(100% - 57px - 60px - 36px);
      // overflow: hidden;
      // .content{
      //   height: 100%;
      //   overflow: auto;
      // }
    }
  }
} 
</style>