import ElementPlus from 'element-plus' 
import '@/assets/styles/index.scss'
import 'nprogress/nprogress.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs' 

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import '@/icons'  
import SvgIcon from '@/components/SvgIcon' 
import BasePopup from '@/components/BasePopup.vue'
export default(app)=>{
  // 使用组件语言（默认英文）更改为中文
  app.use(ElementPlus,{ locale: zhCn})
  // 使用系统图标
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  // svg 图库
  app.component('svg-icon', SvgIcon)
  app.component('BasePopup', BasePopup) 
}
//防抖
const debounce =(fn,delay)=>{
  let timer = null
  return function(){
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function(){
      fn.apply(context,args)
    },delay)
  }
}
// ResizeObserver loop limit exceeded
const _Resize = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _Resize{
  constructor (callback){
    callback = debounce(callback,16)
    super(callback)
  }
}