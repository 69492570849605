import Fingerprint2 from 'fingerprintjs2'

export function requestIdleCallback2() {
  return new Promise((resolve) => {
    Fingerprint2.get(function (components) {
      const values = components.map(component => component.value) // 配置的值的数组
      const murmur = Fingerprint2.x64hash128(values.join(''), 31) // 生成浏览器指纹
      // console.log('写浏览器,requestIdleCallback', murmur)
      localStorage.setItem('browser', JSON.stringify(murmur.toString()))
      resolve({ flag: true, murmur })
    })
  })
}
