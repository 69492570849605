import axios from 'axios' 
import {SHA224} from 'crypto-js'
import useDes from '@/hooks/useDes'
import {UseMessage} from '@/hooks/useMessage'
import router from '@/router'
axios.defaults.headers.post['Content-Type'] = 'application/json'  
axios.defaults.withCredentials = true 

/** 请求拦截 */
axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token')
    if(token){
      // console.log('token',token)
      let obj = useDes().encryptDes()
      let deviceid = JSON.parse(localStorage.getItem('browser'))
      let  timestamp_num = JSON.parse(localStorage.getItem('timestamp')) 
      let timestamp = (timestamp_num ? new Date().getTime() - timestamp_num : new Date().getTime()).toString()
      let noncestr = obj['noncestr']  
      let text = 'username=' +noncestr + '*timestamp=' + timestamp + '%device_id=' +deviceid + '&key=BSFID_4712505'  
      let sign = SHA224(text).toString() 
      config.headers = {
        deviceid,timestamp ,noncestr, sign, username: obj['key'],
        // key:obj['key2']
      } 
    }
    return config
  },
  error => {
    return new Promise.reject(error)
  }
)

/** 回调拦截 */
axios.interceptors.response.use(
  response => {
    if(response.status === 200){
      switch(response.data.status){
        case 1:
          return Promise.resolve(response)
        case 3:
          UseMessage(response.data.msg,'error')
          localStorage.removeItem('token')
          router.push('/login')
          break
        default:
          if(response.data.msg){ 
            UseMessage(response.data.msg,'error') 
          } 
          return Promise.resolve(response)
      }
    }
  },
  error => {
    console.log(error)
    UseMessage(error.message,'error')
  }
)

/**
 * get 方法
 * @param {*} url 
 * @param {*} params 
 * @returns 
 */
export function get(url,params){
  return new Promise((resolve,reject)=>{
    axios.get(url,params).then(res => {
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
  })
}
/**
 * 
 * @param {*} url 
 * @param {*} params 
 * @returns 
 */
export function post(url,params){
  return new Promise((resolve,reject)=>{
    axios.post(url,params).then(res => {
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
  })
}

/**
 * 文件上传
 * @param {*} url 
 * @param {*} params 
 * @param {*} config 
 * @returns 
 */
export function postFile(url,params,config){
  return new Promise((resolve,reject)=>{
    axios.post(url,params,config).then(res => {
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
  })
}